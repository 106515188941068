.project-detail-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .project-detail-page h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }
  
  .project-detail-page .project-detail-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .project-detail-page .left-column,
  .project-detail-page .right-column {
    width: 100%;
  }
  
  .project-detail-page .continue-chat-button {
    display: block;
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .project-detail-page .continue-chat-button:hover {
    background-color: #45a049;
  }
  
  .project-detail-page .conversation-section h3,
  .project-detail-page .right-column h3 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #6396a3;
    text-transform: uppercase;
  }
  
  .project-detail-page .conversation-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
    margin-top: 0;
  }
  
  .project-detail-page .conversation-item {
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 15px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .project-detail-page .conversation-item:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transform: translateY(-2px);
  }
  
  .project-detail-page .conversation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9ecef;
  }
  
  .project-detail-page .conversation-date {
    font-weight: bold;
    color: #495057;
  }
  
  .project-detail-page .conversation-user {
    color: #6c757d;
    font-style: italic;
  }
  
  .project-detail-page .conversation-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .project-detail-page .conversation-engine,
  .project-detail-page .conversation-messages {
    font-size: 0.9em;
    color: #495057;
    background-color: #e9ecef;
    padding: 5px 10px;
    border-radius: 20px;
    width: auto !important;
  }
  
  .project-detail-page .file-upload-form {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .project-detail-page .file-upload-form input[type="file"] {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  .project-detail-page .file-upload-form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .project-detail-page .file-upload-form button:hover {
    background-color: #0056b3;
  }
  
  .project-detail-page .file-list {
    list-style-type: none;
    padding: 0;
  }
  
  .project-detail-page .file-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e9ecef;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .project-detail-page .remove-file-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .project-detail-page .remove-file-button:hover {
    background-color: #c82333;
  }
  
  .project-detail-page .error-message,
  .project-detail-page .success-message {
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  
  .project-detail-page .error-message {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .project-detail-page .success-message {
    background-color: #d4edda;
    color: #155724;
  }

  .project-detail-page .left-column {
    background-color: #dee5eb !important;
  }
  
  @media (min-width: 768px) {
    .project-detail-page .project-detail-content {
      flex-direction: row;
    }
  
    .project-detail-page .left-column {
      flex: 2;
      margin-right: 30px;
    }
  
    .project-detail-page .right-column {
      flex: 1;
    }
  }

  .delete-project-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 2px 18px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    float: right;
    margin-top: -26px;
  }
  
  .delete-project-button:hover {
    background-color: #c82333;
  }
  
  /* Adjust the layout to accommodate the new button */
  .project-detail-content {
    margin-bottom: 20px;
  }