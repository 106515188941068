/* styles/App.css */
/* Custom Font */
/* @font-face {
  font-family: 'Poppins';
  src: url('../fonts/AsapCondensed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */


body {
  font-family: 'Inter', 'Roboto', 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  font-size: 1em;
}

 

/* You can specify different weights for different elements if needed */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', 'Poppins', 'Roboto', sans-serif;
  font-weight: 600;
}

p, span, div {
  font-family: 'Inter', 'Roboto', 'Poppins', sans-serif;
  font-weight: 400;
}

/* You can create utility classes for specific font choices */
.font-inter {
  font-family: 'Inter', sans-serif;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}


.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message {
  margin-bottom: 15px;
  padding: 10px;
  /* border-radius: 8px; */
  max-width: 100%;
}

.message.user {
  align-self: flex-end;
  background-color: #404350;
  color: white;
  word-wrap: break-word;
}

.message.assistant {
  align-self: flex-start;
  background-color: #f1f0f0;
  color: black;
  word-wrap: break-word;
}

.message .role {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.message p {
  margin: 0;
}

/* Styles for HTML content */
.message ul, .message ol {
  padding-left: 20px;
}

.message pre {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

.message code {
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 4px;
}

.input-form {
  display: flex;
  margin-top: 20px;
  margin-bottom: 16px;
}

.input-form input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
}

.input-form button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}

.input-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.engine-selector {
  text-align: center;
  margin-bottom: 20px;
}

.engine-selector button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}


/* New Loader Styles */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  padding: 10px;
  background-color: #f1f0f0;
  border-radius: 18px;
  width: fit-content;
  max-width: 70%;
}

.loader {
  display: flex;
  align-items: center;
}

.loader-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #3498db;
  margin: 0 3px;
  opacity: 0;
  animation: loadingFade 1.5s infinite;
}

.loader-dot:nth-child(1) {
  animation-delay: 0s;
}

.loader-dot:nth-child(2) {
  animation-delay: 0.5s;
}

.loader-dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes loadingFade {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

/* Update the input and button styles */
.input-form input:disabled {
  background-color: #f2f2f2;
  cursor: not-allowed;
  font-family: 'Poppins', sans-serif;
}

.input-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  font-family: 'Poppins', Arial, sans-serif;
}

button{
  font-family: 'Poppins', sans-serif;
}
/* Existing styles... */


* {
  box-sizing: border-box;
}



/* Grid system */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-lg-2, .col-lg-10 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}






/* Left column styles */
.left-column {
  background-color: #2c3e50;
  color: white;
  height: 100vh;
  padding: 20px;
}

.model-selector {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #34495e;
  color: white;
  border: none;
  border-radius: 4px;
}

/* Right column styles */
.right-column {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.chat-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: white;
}

 




html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.chat-layout {
  display: flex;
  height: 100vh;
}

  


.right-column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.chat-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: white;
}

h2 {
  margin-bottom: 20px;
}

.model-selector {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #34495e;
  color: white;
  border: none;
  border-radius: 4px; 
  text-transform: capitalize;
}

.logout-button {
  width: 82%;
  padding: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  bottom: 0px;
  position: absolute;
  margin-bottom: 18px;
}

.logout-button:hover {
  background-color: #c0392b;
}

.input-form {
  display: flex;
  padding: 20px;
  background-color: #ecf0f1;
}

.input-form textarea {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  resize: none;
  overflow: hidden;
  min-height: 40px;
  max-height: 200px;
}

.input-form button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #2980b9;
  color: white;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}

.input-form button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}








.left-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: #2c3e50;
  color: white;
  padding: 20px;
}

.left-column-top {
  flex-grow: 1;
}

.left-column-bottom {
  position: relative;
}

.profile-button {
  width: 100%;
  padding: 10px;
  background-color: #34495e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: left;
}

.profile-button:hover {
  background-color: #2c3e50;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-name {
  font-weight: bold;
  font-size: 14px;
}

.profile-role {
  font-size: 12px;
  opacity: 0.8;
}

.profile-menu {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  background-color: #34495e;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
}

.profile-menu p {
  margin: 0 0 10px 0;
  font-size: 14px;
  word-break: break-all;
}

.profile-menu button {
  width: 100%;
  padding: 8px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profile-menu button:hover {
  background-color: #c0392b;
}







.code-block {
  position: relative;
  background-color: #f4f4f4;
  border-radius: 4px;
  margin: 10px 0;
}

.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background-color: #e0e0e0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.code-language {
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
}

.copy-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #0056b3;
}

.code-block pre {
  margin: 0;
  padding: 10px;
  overflow-x: auto;
}

.code-block code {
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
}
.user-management-link{
  color: #fff;
  padding: 4px;
}


 
.user-management {
  max-width: 75%;
  margin: 0 auto;
  padding: 20px;
}
.user-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.user-management h2 {
  margin-bottom: 20px;
  color: #333;
}

.add-user-form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.add-user-form input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-user-form button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-user-form button:hover {
  background-color: #0056b3;
}

.table-container {
  overflow-x: auto;
  margin-top: 34px;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th,
.user-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.user-table th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #333;
}

.user-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

.user-table tr:hover {
  background-color: #e9ecef;
}

.error {
  color: #dc3545;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .add-user-form {
    flex-direction: column;
  }
  
  .user-table th,
  .user-table td {
    padding: 8px;
  }
}


.home-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.home-button:hover {
  background-color: #218838;
}


.error-message {
  color: #d32f2f;
  background-color: #fde8e8;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.success-message {
  color: #388e3c;
  background-color: #e8f5e9;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.welcome-message {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: left;
  /* font-weight: bold; */
  color: #333;
  text-transform: uppercase;
}



.new-chat-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.new-chat-button:hover {
  background-color: #45a049;
}

.new-chat-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}



.chat-history {
  margin-top: 20px;
}

.chat-history h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.chat-history ul {
  list-style-type: none;
  padding: 0;
} 

.chat-history li {
  padding: 8px;
  margin-bottom: 5px;
  background-color: #34495e;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-history li:hover {
  background-color: #2c3e50;
}

 

.chat-history-item {
  margin-bottom: 10px;
}

.chat-date {
  font-size: 0.8em;
  color: #666;
}

.chat-preview {
  font-size: 0.9em;
  color: #fff;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}




.chat-group {
  margin-bottom: 20px;
}

.chat-group h4 {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.chat-group ul {
  list-style-type: none;
  padding-left: 0;
}

.chat-group li {
  margin-bottom: 10px;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.chat-group li:hover {
  background-color: #516067;
}

.chat-group li.active {
  background-color: #516067;
}


.metadata {
  font-size: 0.8em;
  color: #888;
  margin-top: 5px;
  padding: 5px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.metadata p {
  margin: 2px 0;
}


.chat-history { 
  height: auto;  
  overflow-y: auto;
  margin-top: 20px;
  padding-right: 10px;
  scrollbar-width: none;
  scrollbar-color: #888 #f1f1f1;
  max-height: 37vh; 
}

/* Styles for Webkit browsers (Chrome, Safari, etc.) */
 



.chat-history::-webkit-scrollbar {
  width: 8px;
}

.chat-history::-webkit-scrollbar-track {
  background: #2d3748;
  border-radius: 4px;
}

.chat-history::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 4px;
  border: 2px solid #2d3748;
}

.chat-history::-webkit-scrollbar-thumb:hover {
  background-color: #718096;
}





.chat-history-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-history-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  /* max-width: 800px; */
  max-height: 80%;
  overflow-y: auto;
  display: flex;
  height: calc(100vh - 150px);
}

.chat-history-list ul {
  list-style-type: none;
  padding: 0;
}

.chat-history-list li {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.chat-history-list li.active {
  background-color: #f0f0f0;
}

.conversation-messages {
  margin-top: 20px;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
}

 

.message.assistant {
  background-color: #f0f0f0;
}

.close-button {
  float: right;
}










.chat-history-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto; 
}
 
.back-button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

 
.chat-history-list {
  width: 30%;
  overflow-y: auto;
  border-right: 1px solid #ccc;
  padding-right: 20px;
}

.chat-history-list ul {
  list-style-type: none;
  padding: 0;
}

.chat-history-list li {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
}

.chat-history-list li:hover {
  background-color: #f5f5f5;
}

.chat-history-list li.active {
  background-color: #e6f7ff;
}

.conversation-messages {
  width: 70%;
  overflow-y: auto;
  padding-left: 20px;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}
 

.message.assistant {
  background-color: #f0f0f0;
}

.chat-date, .message-count {
  font-size: 0.8em;
  color: #666;
}

.error-message {
  color: red;
}





.chat-history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.chat-history-header h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.back-button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #e0e0e0;
}


.delete-user-button {
  padding: 5px 10px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s;
}

.delete-user-button:hover {
  background-color: #ff1a1a;
}






.file-upload {
  margin-bottom: 15px;
}

.file-upload input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.upload-progress {
  margin-top: 10px;
  font-size: 14px;
  color: #4CAF50;
}

.upload-error {
  margin-top: 10px;
  font-size: 14px;
  color: #f44336;
}

 
 
.file-upload-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  /* width: 40px;
  height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.file-upload-button:hover {
  background-color: #e0e0e0;
}

.file-upload-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.file-upload-button svg {
  color: #333;
  z-index: 2;
}

.upload-progress-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: rgba(76, 175, 80, 0.3);
  transition: width 0.3s ease;
  z-index: 1;
}

.upload-progress-text {
  font-size: 12px;
  color: #333;
  margin-left: 5px;
}

.upload-error {
  color: #f44336;
  font-size: 12px;
  margin-left: 5px;
}

.file-preview {
  display: flex;
  align-items: center;
  max-width: 200px;
  overflow: hidden;
  cursor: pointer;
}

.preview-image {
  max-width: 40px;
  max-height: 40px;
  object-fit: cover;
  border-radius: 4px;
}

.preview-file {
  display: flex;
  align-items: center;
  gap: 5px;
}

.preview-file svg {
  color: #333;
}

.preview-filename {
  font-size: 12px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
 


.preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.preview-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  z-index: 1;
}

.preview-image-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.preview-image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.preview-pdf {
  width: 100%;
  height: 100%;
  border: none;
}

.preview-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.preview-controls button {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.preview-controls button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.fullscreen .preview-content {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

/* Existing styles for file upload */
.file-upload-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.preview-csv {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;
  padding: 20px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.preview-excel {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;
  padding: 20px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
}


.input-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.voice-search-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  /* width: 40px;
  height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.voice-search-button:hover {
  background-color: #e0e0e0;
}

.voice-search-button.listening {
  background-color: #ff4d4d;
  color: white;
}

.voice-search-button.listening:hover {
  background-color: #ff3333;
}





.voice-search-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.voice-search-button:hover {
  background-color: #e0e0e0;
}

.voice-search-button.listening {
  background-color: #ff4d4d;
  color: white;
}

.voice-search-button.listening:hover {
  background-color: #ff3333;
}

.voice-search-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s ease, height 0.3s ease;
}

.voice-search-button.listening::before {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

.voice-search-button svg {
  z-index: 1;
  transition: transform 0.3s ease;
}

.voice-search-button.listening svg {
  transform: scale(1.1);
}

.voice-feedback {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease, top 0.3s ease;
  pointer-events: none;
  white-space: nowrap;
}

.voice-search-button.listening + .voice-feedback {
  opacity: 1;
  top: calc(100% + 10px);
}














.all-chat-history {
  padding: 20px;
  max-width: 75%;
  margin: 0 auto;
}

.all-chat-history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #e0e0e0;
}

.all-chat-history-content {
  display: flex;
  gap: 20px;
}

.conversation-list {
  width: 30%;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
}

.conversation-list ul {
  list-style-type: none;
  padding: 0;
}

.conversation-list li {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s;
}

.conversation-list li:hover {
  background-color: #f5f5f5;
}

.conversation-list li.active {
  background-color: #d3e0e7;
  color:#fff;
}

.conversation-date, .conversation-user, .message-count {
  font-size: 0.9em;
  color: #666;
}

.conversation-messages {
  width: 70%;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.message.user {
  background-color: #5e6569;
}

.message.assistant {
  background-color: #f0f0f0;
}

.message-role {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
}


.home-button1 {
  padding: 3px 8px;
  font-size: 16px;
  background-color: #7c9b83;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  float: right;
}

.home-button1:hover {
  background-color: #218838;
}


.chat-history-link {
  color: #fff;
  padding: 4px;
  display: block;
  margin-top: 5px;
  text-decoration: none;
}

.chat-history-link:hover {
  text-decoration: underline;
}











.project-management-link {
  display: block;
  padding: 10px;
  margin-top: 10px;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.project-management-link:hover {
  background-color: #45a049;
}

/* Update the profile menu to include styling for the new link */
.profile-menu .project-management-link {
  display: block;
  margin-top: 5px;
  padding: 8px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.profile-menu .project-management-link:hover {
  background-color: #45a049;
}

#projectInput{
  display:none;
}

.project-section {
  margin-bottom: 20px;
  padding: 1px 8px 21px 6px;
  background-color: #34495e;
  border-radius: 8px;
}

.project-section h3 {
  color: #ecf0f1;
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: uppercase;
}








.project-select {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  color: #ecf0f1;
  background-color: #2c3e50;
  border: 1px solid #455a64;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.project-select:hover {
  background-color: #3c5066;
}

.project-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.5);
}

.project-select option {
  background-color: #2c3e50;
  color: #ecf0f1;
}

/* Custom dropdown arrow */
.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: #ecf0f1;
  pointer-events: none;
}