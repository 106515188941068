.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  h1 {
    margin-bottom: 30px;
    color: #1a73e8;
  }
  
  .login-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Style for Google Login button (adjust as needed to match the rendered button) */
  .google-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    background-color: white;
    color: #3c4043;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .google-login-button:hover {
    background-color: #f8f9fa;
  }
  
  /* Style for Microsoft Login button */
  .microsoft-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: 1px solid #8c8c8c;
    border-radius: 4px;
    background-color: white;
    color: #5e5e5e;
    font-family: 'Segoe UI', 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'Poppins', Arial, sans-serif;
  }
  
  .microsoft-login-button:hover {
    background-color: #f3f3f3;
  }
  
  .microsoft-logo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .login-buttons {
      width: 100%;
      padding: 0 20px;
    }
  
    .google-login-button,
    .microsoft-login-button {
      width: 100%;
    }
  }

 
  
  .error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    text-align: center;
    max-width: 350px;
    word-wrap: break-word;
  }