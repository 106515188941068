.project-management {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .project-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .project-management-header h2 {
    margin: 0;
  }
  
  .back-button {
    padding: 10px 20px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    float: right;
  }
  
  .back-button:hover {
    background-color: #5a6268;
  }
  
  .create-project-form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto 40px;
  }
  
  .create-project-form input,
  .create-project-form textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .create-project-form button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
 
  
  .project-box h3 {
    margin-top: 0;
  }
  
  .project-box button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .file-upload-form {
    margin-top: 20px;
  }
  
  .file-upload-form input[type="file"] {
    margin-bottom: 10px;
  }
  
  .project-files {
    margin-top: 20px;
  }
  
  .project-files ul {
    list-style-type: none;
    padding: 0;
  }
  
  .project-files li {
    background-color: #e9ecef;
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 4px;
  }
  
  .error-message,
  .success-message {
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  
  .error-message {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .success-message {
    background-color: #d4edda;
    color: #155724;
  }




.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.popup-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.form-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions button[type="submit"] {
  background-color: #4CAF50;
  color: white;
}

.form-actions button[type="button"] {
  background-color: #f44336;
  color: white;
}

.create-project-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}





 






.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px 0;
}

.project-box {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.project-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  border-color: #3498db;
}

.project-box h3 {
  margin: 0 0 10px 0;
  color: #2c3e50;
  font-size: 1.2em;
}

.project-box p {
  color: #7f8c8d;
  font-size: 0.9em;
  margin-bottom: 10px;
  flex-grow: 1;
}

.project-date {
  font-size: 0.8em;
  color: #95a5a6;
  margin-top: auto;
}

.project-box::after {
  content: '→';
  position: absolute;
  bottom: 10px;
  right: 15px;
  font-size: 1.2em;
  color: #3498db;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-box:hover::after {
  opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .project-list {
    grid-template-columns: 1fr;
  }
}

/* Additional styles for the create project button */
.create-project-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
  display: inline-block;
}

.create-project-button:hover {
  background-color: #2980b9;
}




.h2class{
  text-transform: uppercase; 
  font-size: 20px; 
  color: #46a9b5;
}


.project-detail-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 20px;
}

.back-button:hover {
  background-color: #2980b9;
}

.project-detail-header h2 {
  margin: 0;
}

@media (max-width: 768px) {
  .project-detail-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .back-button {
    margin-bottom: 10px;
  }
}